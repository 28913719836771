import React, { Component } from 'react';

class VisualDesignClub extends Component {
  render() {
    return (
      <section className="col-7 nested">
        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">At Lambda School, I formed a weekly learning group for the design students called <strong>Visual Design Club</strong>! It's a space for me to prototype new visual design teaching methodologies while students learn advanced content.</p>
          <p className="reflections col-2">I called it a club because I'm learning along with the students! Not all the activities and lessons in visual design club are a hit. It's a space for us to experiment and learn from each other.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">Visual design is one of the hardest design verticals to master because so much of it feels <strong>subjective</strong>. My goal is to make visual design feel both objective and experimental at the same time. With all my classes, I follow these principles:</p>
        </div>

        <ul className="text-list medium-spacer-bottom col-5">
          <li><strong>Change it up.</strong> I never follow the same teaching style for the entire lesson. I use a combination of short lectures, group activities, and games.</li>
          <li><strong>Disguise assessment.</strong> Whenever I assess student proficiency, I disguise it as a fun activity to lower the pressure for the students.</li>
          <li><strong>Always give feedback.</strong> Especially with visual design, the best way to improve is to get constant detailed feedback. Even with in-class activities, I give small crits on how they can improve in the future.</li>
          <li><strong>Make space for exploration.</strong> Learning visual design can feel like a laundry list of rules. I leave space for students to break those boundaries.</li>
        </ul>

        <h2 className="large-spacer-top col-5">Visual design puzzles</h2>
        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">I start every lesson with a design puzzle. Design puzzles are an example of <strong>disguising assessments</strong>. After all the students have trickled in, I walk through best practices for completing the puzzle! These are typically technical craft skills.</p>
          <p className="reflections col-2">These are similar to Do Now's in math education! Teachers will start a math lesson with 1-2 problems on the board while they take attendance or check homework.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">In this puzzle, the goal was for students to organize the pink squares as quickly as possible. The trick was to ungroup everything and use the tidy up tool!</p>
        </div>

        <div className="text-group">
          <div className="col-5 medium-spacer-bottom">
            <video className="project-image" alt="organize the squares" src={require("../assets/images/visual-design-club/organize-the-squares.mov")} muted autoPlay loop/>
          </div>
          <p className="reflections col-2">In this puzzle, I'm assessing their previous knowledge of grouped elements and alignment tools.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">Sometimes, I use multiple puzzles for the entire class that build upon one another. In the 1st puzzle, the students try to recreate a set of stars with the proper constraints.</p>
        </div>

        <div className="text-group">
          <div className="col-5 medium-spacer-bottom">
            <video className="project-image" alt="design the stars puzzle" src={require("../assets/images/visual-design-club/puzzle-stars.mp4")} muted autoPlay loop/>
          </div>
          <p className="reflections col-2">The 1st puzzle is almost always a pre-test where I decide how I should pace the rest of my lecture. In this one, I'm testing knowledge of star objects and constraints.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">In the 2nd puzzle, we take the rounded star concept and put it on a donut. We like to build skills upon each other to increase their retention of new ideas. The students work in the same space, so they can see what everyone else is trying.</p>
        </div>

        <div className="text-group">
          <div className="col-5 medium-spacer-bottom">
            <video className="project-image" alt="design the stars puzzle" src={require("../assets/images/visual-design-club/puzzle-donut.mp4")} muted autoPlay loop/>
          </div>
          <p className="reflections col-2">This puzzle assesses how well they retained the star concepts from puzzle #1.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">The last puzzle was all about typography red flags. I created a donut <span role="img" aria-label="donut">🍩</span> recipe page that violated typography principles for them to fix.</p>
        </div>

        <div className="col-5 medium-spacer-bottom">
          <img className="project-image" alt="Original puzzle image" src={require("../assets/images/visual-design-club/puzzle-typography.jpeg")}/>
        </div>

        <div className="col-5 medium-spacer-bottom">
          <video className="project-image" alt="design the stars puzzle" src={require("../assets/images/visual-design-club/puzzle-typography.mp4")} muted autoPlay loop/>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">These puzzles have been a great way to teach and assess technical skills! Students tend to retain the material better using this hands-on format.</p>
        </div>

        <h2 className="large-spacer-top col-5">Exploratory activities</h2>
        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">I love introducing open-ended activities in class that push students to explore the boundaries of visual design. It's important for me to balance following the rules with breaking them!</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">This one is inspired by Figma's community quilt from Config. I created a color palette for the group and had them draw an illustration that represented themselves.</p>
        </div>

        <div className="col-5 medium-spacer-bottom">
          <video className="project-image" alt="Quilt composed of squares with illustrations" src={require("../assets/images/visual-design-club/experiment-quilt.mov")} muted autoPlay loop/>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">In the following activity, I have students choose an emotion (e.g. surprised, anxious, bored). Their task is to create a composition with just shape and color that communicates the emotion. After the activity, everyone tries to guess each other's emotions.</p>
        </div>

        <div className="col-5 medium-spacer-bottom">
          <video className="project-image" alt="Shape & color classroom activity where students use shapes and colors to convey different emotions" src={require("../assets/images/visual-design-club/experiment-shape-color.mov")} muted autoPlay loop/>
        </div>

        <h2 className="large-spacer-top col-5">Collaboration in everything</h2>
        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">After a quick lecture and solo activity, I group students to work on a prompt together. We recently taught a lesson about the History of GUIs to show how trends have evolved. After the lesson, we put the students into groups to research the history of the UI of a popular product.</p>
        </div>

        <div className="col-7 medium-spacer-bottom">
          <img className="project-image" alt="Old exercise UI" src={require("../assets/images/visual-design-club/groups-timelines.png")}/>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">In an intro to data visualization class, I gave students a series of prompts all related to Netflix. After giving them a general overview of data visualization principles, the students worked in groups to sketch out an idea for a visualization!</p>
        </div>

        <div className="col-7 medium-spacer-bottom">
          <img className="project-image" alt="Old exercise UI" src={require("../assets/images/visual-design-club/what-visualization.png")}/>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">After the activity, each group shared their thought process with the rest of the class to get feedback! I always encourage students to focus on their process and not the outcome. Especially with short activities, the process is always more important!</p>
        </div>

        <div className="col-7 medium-spacer-bottom">
          <img className="project-image" alt="Old exercise UI" src={require("../assets/images/visual-design-club/netflix-vis.png")}/>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">Group activities are great because they build community within the design program by allowing students to learn from one another.</p>
        </div>

        <h2 className="large-spacer-top col-5">Teaching is the best way to learn</h2>
        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">I learn something new every time I teach. There are always ways to make lessons more effective and fun! I love being able to iterate on my lessons on a weekly basis.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">Visual Design Club has been a fantastic experimental space for me to try new things. I'm always looking for new ideas, so don't hesitate to reach out if you want to brainstorm together.</p>
        </div>


    </section>
    );
  }
}

export default VisualDesignClub;
