import React, { Component } from 'react';

class Labs extends Component {
  render() {
    return (
      <section className="col-7 nested">
        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">I joined Lambda School in July 2019 to build the design student experience for the Labs program. After four months of instruction, students from every track join Labs to gain real-world experience working on a product team.</p>

          <p className="reflections col-2">We onboard a new cohort of students every month. It's basically like kicking off an internship program with 100+ students!</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5">During Labs, design students work with a team of 6-8 developers and a team lead to ship a product in 8 weeks. Our goal is for every design student to exit Labs with a hiring manager approved project. We conduct research about design hiring managers to set our project quality bar.</p>
          <p className="reflections col-2">Labs is the first time most students have worked in a cross-functional product team.</p>
        </div>

        <h2 className="large-spacer-top col-5">Onboarding lessons</h2>
        <div className="text-group">
          <p className="large-paragraph small-spacer-bottom col-5">The first two weeks of Labs are filled with onboarding lessons. The goal of the design onboarding curriculum is to set up students for success on their teams. Students should leave Labs being experts in:</p>
          <p className="reflections col-2">We name these "onboarding" lessons to mirror onboarding onto a real product team.</p>
        </div>

        <ul className="text-list medium-spacer-bottom col-5">
          <li>Delivering quality</li>
          <li>Documenting work</li>
          <li>Collaborating with others</li>
          <li>Working in a distributed team</li>
          <li>Implementing feedback</li>
        </ul>

        <h3 className="project-subheading medium-spacer-top small-spacer-bottom col-5"><span role="img" aria-label="sparkle">✨</span> <strong>Lesson 1:</strong> Welcome to Labs design!</h3>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">With every new cohort, I start with a profile page activity for us to get to know each other better! I created a profile page component in Figma and have each student customize their own.</p>
          <p className="reflections col-2">It's also secretly as a way for me to assess Figma component proficiency <span role="img" aria-label="shhh">🤫</span></p>
        </div>

        <div className="col-5 medium-spacer-bottom">
          <img className="project-image" alt="Student profile cards that showcase their backgrounds and unique skills" src={require("../assets/images/labs/student-profiles.png")}/>
        </div>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">We also set expectations during this lesson so that students know how to succeed during Labs! We encourage students to create their design processes and iterate constantly.</p>
        </div>

        <h3 className="project-subheading medium-spacer-top small-spacer-bottom col-5"><span role="img" aria-label="sparkle">✨</span> <strong>Lesson 2:</strong> Design collaboration</h3>

        <div className="text-group">
          <p className="large-paragraph small-spacer-bottom col-5">Labs is the first time design students work together on a project. In this lesson, we teach students how to:</p>
        </div>

        <ul class="text-list medium-spacer-bottom col-5">
          <li>divide up design work on a team</li>
          <li>collaborate with a group of designers</li>
          <li>bring design to the rest of their product team</li>
        </ul>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">After a short lecture, we do a brainstorming activity in Figma about connecting superheros with situations in their neighborhood.</p>
        </div>

        <div className="col-5 medium-spacer-bottom">
          <img className="project-image" alt="Student profile cards that showcase their backgrounds and unique skills" src={require("../assets/images/labs/collab-brainstorm.png")}/>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5">Following the class brainstorm, I split the students up into breakout room groups to sketch out some initial ideas using a design library.</p>
        </div>

        <h3 className="project-subheading medium-spacer-top small-spacer-bottom col-5"><span role="img" aria-label="sparkle">✨</span> <strong>Lessons 3-5:</strong> Visual design series</h3>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">Through our design hiring manager research, we learned that understanding visual design is crucial for entry-level designer roles. Based on common mistakes in previous projects, I set a solid foundation of visual design rules and guidelines. We split visual design into these sections:</p>
        </div>

        <ul className="text-list medium-spacer-bottom col-5">
          <li>Typography</li>
          <li>Shape</li>
          <li>Color</li>
          <li>Effects</li>
          <li>Grid & layout</li>
        </ul>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">After we go over basic type principles, I put students in groups to work on developing a type system for <strong>Alice's Adventures in Wonderland</strong>.</p>
        </div>

        <div className="col-7 medium-spacer-bottom">
          <video className="project-image" alt="typography exercise" src={require("../assets/images/labs/type-exercise.mov")} muted autoPlay loop/>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">We encourage students to use one font family with a variety of weights, sizes, and styles. If they understand hierarchy with one font family, they can start experimenting with more unique heading typefaces.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">With the color & shape lesson, I simplify visual design into basic forms. We do a series of exercises where I have students answer these questions accompanied with the why.</p>
        </div>

        <div className="col-5 small-spacer-bottom">
          <img className="project-image" alt="Circle or rectangle" src={require("../assets/images/labs/which-one-1.png")}/>
        </div>

        <div className="col-5 small-spacer-bottom">
          <img className="project-image" alt="Circle or rectangle" src={require("../assets/images/labs/which-one-3.png")}/>
        </div>

        <div className="col-5 small-spacer-bottom">
          <img className="project-image" alt="Circle or rectangle" src={require("../assets/images/labs/which-one-2.png")}/>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">The goal of these lessons is to show how all user interfaces boil down to three simple forms: circles, rectangles, and lines. After these lessons, we run an activity where student groups style the same news home page for various user groups.</p>
        </div>

        <div className="col-5 small-spacer-bottom">
          <img className="project-image" alt="Example of a visual design lesson prompt" src={require("../assets/images/labs/prompt-example.png")}/>
        </div>

        <h3 className="project-subheading medium-spacer-top small-spacer-bottom col-5"><span role="img" aria-label="sparkle">✨</span> <strong>Lesson 6:</strong> Documentation</h3>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">In Labs, we focus a lot on documentation and iteration. Especially with new designers, it's important to iterate. The first thing you draw is never the best solution! I teach the students to go for quantity of iteration moving 1 change at a time.</p>
          <p className="reflections col-2">I learned to iterate one change at a time during my first designer job from my first design mentor! It helped me communicate my thought process to the team.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">In the activity below, I put students into groups of 3-4 to iterate on the same music player screen. The goal is for each group to make the largest quantity of designs!</p>
        </div>

        <div className="col-7 medium-spacer-bottom">
          <video className="project-image" alt="Collection of music player screens that all look slightly different from each other" src={require("../assets/images/labs/iterations.mov")} muted autoPlay loop/>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">After 15 minutes of iterating, I have the groups discuss which designs are their favorites. Why do you feel the designs you chose are the most effective?</p>
        </div>

        <h3 className="project-subheading medium-spacer-top small-spacer-bottom col-5"><span role="img" aria-label="sparkle">✨</span> <strong>Lesson 7:</strong> Design critiques</h3>

        <div className="text-group">
          <p className="large-paragraph col-5">We teach students how to get the most out of design critiques. They learn to prepare questions for the group:</p>
        </div>

        <ul className="text-list medium-spacer-bottom col-5">
          <li>What type of feedback are you looking for?</li>
          <li>What questions do you have for the group?</li>
          <li>What challenges do you need help with?</li>
        </ul>

        <div className="text-group">
          <p className="large-paragraph medium-spacer-bottom col-5">I have the students pick their favorite iterations from the documentation lesson and write 3 questions they want us to answer. As a class, we go through and leave comments on their questions!</p>
        </div>

        <div className="text-group">
          <div className="col-5 small-spacer-bottom">
            <img className="project-image" alt="Student work with comment son it" src={require("../assets/images/labs/crit.jpeg")}/>
          </div>
          <p className="reflections col-2">I'm surprised we didn't break the Figma file with our comments <span role="img" aria-label="scream">😱</span></p>
        </div>

        <h3 className="project-subheading medium-spacer-top small-spacer-bottom col-5"><span role="img" aria-label="sparkle">✨</span> <strong>Lesson 8:</strong> Design systems</h3>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">We close out the Labs design onboarding sessions with a couple lessons on design systems. We focus on the fundamentals: type & color styles, using existing components, and defining a button component.</p>
        </div>

        <div className="text-group">
          <div className="col-5 small-spacer-bottom">
            <img className="project-image" alt="Students working on button components" src={require("../assets/images/labs/chaos.png")}/>
          </div>
          <p className="reflections col-2">This lesson is usually chaotic because there are 20+ students creating components and styles at the same time!</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph col-5 small-spacer-bottom">These are just the lessons for design students! There's an additional curriculum for all tracks. We improve the lessons every time we teach to a new cohort based upon feedback, reflection, and industry expectations.</p>
        </div>

        <h2 className="large-spacer-top col-5">Design critiques</h2>
        <div className="text-group">
          <p className="large-paragraph small-spacer-bottom col-5">During week 3, we begin hosting weekly design critiques. All teams are required to present once a week at design crit. We encourage the students to come prepared with specific questions about their work! Some students create their own feedback worksheets.</p>
        </div>

        <div className="text-group">
          <p className="large-paragraph small-spacer-bottom col-5">As design managers, we track the design quality of projects on a weekly basis.</p>
        </div>

    </section>
    );
  }
}

export default Labs;
