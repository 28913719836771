import React, { Component } from 'react';
import './Sparkle.scss'

class Sparkle extends Component {
  render() {
    return (
      <img className="sparkle" alt="Flashing sparkle" src="./images/sparkle.gif" />
    );
  }
}

export default Sparkle
